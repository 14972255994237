import * as React from 'react';
import styled from 'styled-components';
import { MdxFrontmatter } from '../types/mdx';
import Page from '../components/Page';
import Container from '../components/Container';
import Markdown from '../components/Markdown';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Background = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(12, 75px);
  top: 50%;
  transform: translateY(-50%);
  left: -200px;
  right: -200px;
  z-index: 0;

  .item-1 {
    grid-column: 3 / span 2;
    grid-row: 5;
  }

  .item-2 {
    grid-column: 3 / span 4;
    grid-row: 7;
  }

  .item-3 {
    grid-column: 20 / span 4;
    grid-row: 3;
  }

  .item-4 {
    grid-column: 22 / span 3;
    grid-row: 5;
  }

  .item-5 {
    grid-column: 21 / span 3;
    grid-row: 6;
  }

  .item-6 {
    grid-column: 16 / span 3;
    grid-row: 9;
  }

  .item-7 {
    grid-column: 15 / span 3;
    grid-row: 10;
  }

  .item-8 {
    grid-column: 6 / span 3;
    grid-row: 1;
  }
`;

interface Props {
  location: Location;
  pageContext: {
    frontmatter: MdxFrontmatter;
  };
  children: React.ReactElement | React.ReactElement[];
}

function PageTemplate({ location, pageContext, children }: Props) {
  const { frontmatter } = pageContext;

  const meta = {
    title: frontmatter.title || '',
    description: frontmatter.description || '',
  };

  return (
    <Page
      location={location}
      title={meta.title}
      description={meta.description}
      header={<Header search={false} />}
    >
      <Background className="fixed bottom-0 opacity-75 dark:opacity-25">
        <div className="item-1 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-2 rounded bg-purple-200 dark:bg-purple-600" />
        <div className="item-3 rounded bg-purple-200 dark:bg-purple-600" />
        <div className="item-4 rounded bg-purple-300 dark:bg-purple-700" />
        <div className="item-5 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-6 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-7 rounded bg-purple-300 dark:bg-purple-600" />
        <div className="item-8 rounded bg-purple-100 dark:bg-purple-900" />
      </Background>
      <div className="relative z-10">
        <Container size="xs">
          <div className="mb-24">
            {!!meta.title && (
              <div className="mt-20">
                <h1 className="text-center text-5xl font-semibold mb-16">{meta.title}</h1>
              </div>
            )}
            <Markdown.Provider>{children}</Markdown.Provider>
          </div>
        </Container>
      </div>
      <Footer />
    </Page>
  );
}

export default PageTemplate;
