import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page-mdx.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "privacy-policy",
      "style": {
        "position": "relative"
      }
    }}>{`Privacy Policy`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: This policy mainly relates to versions prior to Notifee being made `}<a parentName="p" {...{
          "href": "https://invertase.io/blog/open-sourcing-notifee"
        }}>{`free and Open Source`}</a>{`.`}</p>
    </blockquote>
    <p>{`Effective date: 13 January 2020`}</p>
    <p>{`Notifee ("us", "we", or "our") operates the notfiee.app website (the "Service").`}</p>
    <p>{`This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our
Service and the choices you have associated with that data.`}</p>
    <p>{`We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of
information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy
Policy have the same meanings as in our Terms and Conditions, accessible from notifee.app/terms-and-conditions.`}</p>
    <h2 {...{
      "id": "third-party-consent",
      "style": {
        "position": "relative"
      }
    }}>{`Third Party Consent`}</h2>
    <p>{`All third-party product names, logos, and brands are property of their respective owners. All third-party company,
product and service names used in this website are for identification purposes only. Use of these names, logos, and
brands does not imply copyright ownership.`}</p>
    <h2 {...{
      "id": "information-collection-and-use",
      "style": {
        "position": "relative"
      }
    }}>{`Information Collection and Use`}</h2>
    <p>{`We collect several different types of information for various purposes to provide and improve our Service to you. We do
not share your email address to third-parties or use it for marketing purposes. Email addresses are used for transactional
emails only e.g. purchase or invoice emails.`}</p>
    <h3 {...{
      "id": "personal-data",
      "style": {
        "position": "relative"
      }
    }}>{`Personal Data`}</h3>
    <p>{`While using our Service, we may ask you to provide us with certain personally identifiable information that can be used
to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Cookies & Usage Data`}</li>
      <li parentName="ul">{`Email`}</li>
      <li parentName="ul">{`Usage Data`}</li>
    </ul>
    <p>{`We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include
information such as your, browser type, browser version, the
pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other
diagnostic data.`}</p>
    <h2 {...{
      "id": "use-of-data",
      "style": {
        "position": "relative"
      }
    }}>{`Use of Data`}</h2>
    <p>{`Notifee uses the collected data for various purposes:`}</p>
    <ul>
      <li parentName="ul">{`To provide and maintain the Service`}</li>
      <li parentName="ul">{`To provide customer care and support`}</li>
      <li parentName="ul">{`To notify you about changes to our Service`}</li>
      <li parentName="ul">{`To allow you to participate in interactive features of our Service when you choose to do so`}</li>
      <li parentName="ul">{`To provide analysis or valuable information so that we can improve the Service`}</li>
      <li parentName="ul">{`To monitor the usage of the Service`}</li>
      <li parentName="ul">{`To detect, prevent and address technical issues`}</li>
    </ul>
    <h3 {...{
      "id": "transfer-of-data",
      "style": {
        "position": "relative"
      }
    }}>{`Transfer of data`}</h3>
    <p>{`Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your
state, province, country or other governmental jurisdiction where the data protection laws may differ than those from
your jurisdiction.`}</p>
    <p>{`If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the
data, including Personal Data, to United Kingdom and process it there.`}</p>
    <p>{`Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.`}</p>
    <p>{`Notifee will take all steps reasonably necessary to ensure that your data is treated securely and in
accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a
country unless there are adequate controls in place including the security of your data and other personal information.`}</p>
    <h2 {...{
      "id": "disclosure-of-data",
      "style": {
        "position": "relative"
      }
    }}>{`Disclosure Of Data`}</h2>
    <h3 {...{
      "id": "legal-requirements",
      "style": {
        "position": "relative"
      }
    }}>{`Legal Requirements`}</h3>
    <p>{`Notifee may disclose your Personal Data in the good faith belief that such action is necessary to:`}</p>
    <ul>
      <li parentName="ul">{`To comply with a legal obligation`}</li>
      <li parentName="ul">{`To protect and defend the rights or property of Notifee`}</li>
      <li parentName="ul">{`To prevent or investigate possible wrongdoing in connection with the Service`}</li>
      <li parentName="ul">{`To protect the personal safety of users of the Service or the public`}</li>
      <li parentName="ul">{`To protect against legal liability`}</li>
    </ul>
    <h3 {...{
      "id": "security-of-data",
      "style": {
        "position": "relative"
      }
    }}>{`Security Of Data`}</h3>
    <p>{`The security of your data is important to us, but remember that no method of transmission over the Internet, or method
of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data,
we cannot guarantee its absolute security.`}</p>
    <h3 {...{
      "id": "analytics",
      "style": {
        "position": "relative"
      }
    }}>{`Analytics`}</h3>
    <p>{`We may use third-party Service Providers to monitor and analyze the use of our Service.`}</p>
    <ul>
      <li parentName="ul">{`Google Analytics: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services.`}</li>
    </ul>
    <p>{`Note: We use Google Analytics with the default advertising features turned OFF, this means your data is NOT shared with
DoubleClick for advertising/remarketing purposes.`}</p>
    <h2 {...{
      "id": "links-to-other-sites",
      "style": {
        "position": "relative"
      }
    }}>{`Links to other sites`}</h2>
    <p>{`Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be
directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We
have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites
or services.`}</p>
    <h2 {...{
      "id": "childrens-privacy",
      "style": {
        "position": "relative"
      }
    }}>{`Children's Privacy`}</h2>
    <p>{`Our Service does not address anyone under the age of 18 ("Children").`}</p>
    <p>{`We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or
guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware
that we have collected Personal Data from children without verification of parental consent, we take steps to remove
that information from our servers.`}</p>
    <h2 {...{
      "id": "changes-to-this-privacy-policy",
      "style": {
        "position": "relative"
      }
    }}>{`Changes To This Privacy Policy`}</h2>
    <p>{`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
on this page.`}</p>
    <p>{`We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and
update the "effective date" at the top of this Privacy Policy.`}</p>
    <p>{`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
when they are posted on this page.`}</p>
    <h2 {...{
      "id": "contact-us",
      "style": {
        "position": "relative"
      }
    }}>{`Contact Us`}</h2>
    <p>{`If you have any questions about this Privacy Policy, please contact us via the 'Contact Us' page on this website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      