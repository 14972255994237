import * as React from 'react';
import { icons } from '@invertase/ui';

import Link from './Link';
import useDarkMode from '../hooks/useDarkMode';
import Lottie from './Lottie';

const { LightBulb } = icons;

interface FooterLink {
  text: string;
  path: string;
}

const linksHelp: FooterLink[] = [
  { text: 'Contact Us', path: '/contact' },
  { text: 'GitHub', path: 'https://github.com/invertase/notifee' },
  { text: 'Twitter', path: 'https://twitter.com/notifee_app' },
];

const linksOtherProducts: FooterLink[] = [
  { text: 'Invertase', path: 'https://invertase.io' },
  { text: 'GitHub', path: 'https://github.com/invertase' },
  { text: 'Twitter', path: 'https://twitter.com/invertaseio' },
  { text: 'React Native Firebase', path: 'https://rnfirebase.io' },
];

const linksProduct: FooterLink[] = [
  { text: 'Frequently Asked Questions', path: '/frequently-asked-questions' },
  // { text: 'Account', path: '/account' },
  { text: 'Terms & Conditions', path: '/terms-and-conditions' },
  { text: 'Privacy Policy', path: '/privacy-policy' },
  { text: 'Licensing Terms', path: '/license-terms' },
  // { text: 'Purchase', path: '/purchase' },
];

function FooterSectionWithLinks({ links, header }: { links: FooterLink[]; header: string }) {
  return (
    <div className="my-4">
      <h4 className="uppercase text-xs font-extrabold mt-6">{header}</h4>
      <ul>
        {links.map((link: FooterLink) => (
          <li className="mt-2 text-base" key={link.path}>
            <Link to={link.path} className="hover:underline">
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function Footer() {
  const { toggleDarkMode } = useDarkMode();
  return (
    <footer className="bg-gray-900 dark:bg-gray-800 relative pt-8 pb-10 md:mt-24 text-white text-base">
      <div className="max-w-4xl mx-auto text-white block md:flex relative z-10 text-center">
        <div className="md:w-1/4 flex flex-col items-center">
          <Lottie
            data={require('../../resources/lottie-logo-data.json')}
            elementId="footer-logo"
            className="w-12 h-12 mb-4"
          />
          <div className="mt-2">NOTIFEE</div>
          <div className="text-xs opacity-75 mt-2">&copy; Copyright Invertase Limited</div>
          <div
            role="button"
            className="my-4 text-center flex items-center justify-center hover:underline focus:outline-none"
            onClick={() => toggleDarkMode()}
            onKeyPress={() => toggleDarkMode()}
            tabIndex={0}
          >
            <LightBulb size={16} className="text-yellow-900 dark:text-yellow-400 mr-2" />
          </div>
        </div>
        <div className="md:w-1/4">
          <FooterSectionWithLinks links={linksHelp} header="Get In Touch" />
        </div>
        <div className="md:w-1/4">
          <FooterSectionWithLinks links={linksOtherProducts} header="Invertase" />
        </div>
        <div className="md:w-1/4">
          <FooterSectionWithLinks links={linksProduct} header="Notifee" />
        </div>
      </div>
      <div className="text-center my-auto mx-auto text-white block mt-16 text-xs text-gray-400 px-10">
        Notifee is not affiliated with, or endorsed by, Facebook and/or its partners.
        <br />
        React Native is an open-source mobile application framework created by Facebook.
      </div>
    </footer>
  );
}

export default Footer;
