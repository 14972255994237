import React, { CSSProperties } from 'react';
import lottie from 'lottie-web';

interface Props {
  elementId: string;
  data?: object;
  light?: object;
  dark?: object;
  loop?: boolean;
  autoplay?: boolean;
  className?: string;
  style?: CSSProperties;
}

function Lottie({ elementId, loop = true, autoplay = true, style, className, data }: Props) {
  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!container.current || container.current.childElementCount > 1) {
      return;
    }

    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: data,
    });
  }, [elementId]);

  return <div id={elementId} ref={container} className={className} style={style} />;
}

export default Lottie;
